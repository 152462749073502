// 👇️ ts-nocheck ignores all ts errors in the file
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-nocheck
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { refreshTableBannersProducts, selectBannersProductsRefreshTable } from "../../../redux";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { bannerProductsService } from "../../../shared/services";
import useAppDispatch from '../../../redux/hooks/useAppDispatch';


interface Product {
    id:                      number;
    name:                    string;
    price:                   number;
    price_with_tax:          number;
    discount_price:          number;
    discount_price_whit_tax: number;
    status:                  string;
    slug:                    string;
    course:                  string;
    type_media:              string;
    main_image:              string;
    products_media_id:       number;
}

export interface ISingleProductForBanner {
    id:         number;
    banner_id:  number;
    product_id: number;
    createdAt:  Date;
    updatedAt:  Date;
    deletedAt:  null;
    product:    Product;
}

export const useBannersProducts = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const isRefreshTableBannersProducts = useSelector(selectBannersProductsRefreshTable);
    const { pathname  } = useLocation();
    const {id} =useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();
    const [selectedValue, setSelectedValue] = useState<string>()
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

    const changeSelectValue = (val: string) => {
      setSelectedValue(val);
    };

      const onSubmitCreateProductForBanner = async()=>{
        if(isLoading) return
        setIsLoading(true);
        if(!selectedValue){
            setIsLoading(false)
            return message.error(`Debes de seleccionar un producto antes añadirlo a un banner`);
        }
        await createProductForBanner(Number(selectedValue),Number(id))
            .then((res: any) => {
              setIsLoading(false);
              if (res.id) {
                message.success(
                  `Se ha añadido con éxito el producto al banner con id:${res.id}`
                );
                dispatch(refreshTableBannersProducts(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
      }

      const createProductForBanner = async(product_id: number, banner_id: number) =>{
        return await bannerProductsService.create({product_id, banner_id})
        .then((res: any) => {return res})
        .catch((err: any) => { return err.message })
      }


    useEffect(() => {
        if (isRefreshTableBannersProducts) {
          tableProps?.refresh();
          dispatch(refreshTableBannersProducts(false));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isRefreshTableBannersProducts]);

  return {
    //State
    id,
    isLoading,
    isRefreshTableBannersProducts,
    tableProps,
    selectedValue,
    modalIsOpen,
    //methods
    setTableProps,
    setModalIsOpen,
    //functions
    onSubmitCreateProductForBanner,
    changeSelectValue,
  }
}
