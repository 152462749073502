import { useSelector } from "react-redux";
import { useUsers } from "../../../../modules/users/hooks/useUsers";
import {
  selectModalUser,
  selectIsUpdateUser,
  selectUserForUpdate,
} from "../../../../redux";
import { useFormContext } from "react-hook-form";
import { IUserResFind } from "../../../../redux/@types";
import { Button, Col, Row, Table, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import { WrapperModalUsers } from "../../../../shared/components/ui/modals/styled";
import { addressesUsersService } from "../../../../shared/services";
import { InputNumber, InputText } from "../../../../shared/components";

const { Title } = Typography;

export interface Props {
  isUpdateUser: boolean
  modalVisible: boolean
  userInfo?: any
  handleCancel: any
  onSubmitCreateOrUpdate: any
  isLoading: boolean
}

export const ModalDiscount: FC<Props> = ({isUpdateUser, modalVisible, userInfo, handleCancel, isLoading, onSubmitCreateOrUpdate}) => {

  const { control, handleSubmit: onSubmit } = useFormContext<any>();

  return (
    <WrapperModalUsers
      title={
        isUpdateUser
          ? `Actualizando Descuento: '${userInfo?.id!}'`
          : `Creando Descuento`
      }
      open={modalVisible}
      width={"640px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={handleCancel}
    >
      <Row gutter={16}>
        <Col
          span={12}
          style={{
            display: "flex",
            margin:"0",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Descripción:
          </Title>
          <InputText
            name="descripcion"
            control={control}
            placeHolder="Ingrese el porcentaje de descuento ej: 10"
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            margin:"0",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Porcentaje de descuento:
          </Title>
          <InputNumber
            name="discount_percentage"
            control={control}
            placeHolder="Ingrese el porcentaje de descuento ej: 10"
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Cantidad Minima:
          </Title>
          <InputNumber
            name="min_quantity"
            control={control}
            placeHolder="Ingrese la cantidad minima"
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Cantidad máxima:
          </Title>
          <InputNumber
            name="max_quantity"
            control={control}
            placeHolder="Ingrese la cantidad máxima"
          />
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Posición:
          </Title>
          <InputNumber
            name="position"
            control={control}
            placeHolder="Posición"
          />
        </Col>
      </Row>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateUser ? "Actualizar información" : "Crear descuento"}
      </Button>
    </WrapperModalUsers>
  );
};
