import { Button, Col, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useBannersProducts } from "../../../../modules";
import { WrapperModalBanners } from "../../../../modules/banners/ui/styled";
import { SimpleInputSelect } from "../inputs";
import { productsService } from "../../../services";

interface Product {
  id:                      number;
  name:                    string;
  price:                   number;
  price_with_tax:          number;
  discount_price:          number;
  discount_price_whit_tax: number;
  status:                  string;
  slug:                    string;
  course:                  string;
  type_media:              string;
  main_image:              string;
  products_media_id:       number;
}

const { Title } = Typography;

interface Props {
    modalIsOpen: boolean
    setModalIsOpen: Dispatch<SetStateAction<boolean>>
}
export const ModalBannersProducts = ({modalIsOpen,setModalIsOpen}:Props) => {
  const [arrOptionsProducts, setArrOptionsProducts] = useState<Array<{
    value: string;
    title:string;
  }>>([])

  useEffect(() => {
    if(arrOptionsProducts.length  === 0){
        serviceSelector()
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serviceSelector = async() => {
    // await productsService.find({ query: {  $limit: 9999999999999, } })
    await productsService.find({ query: {  $limit: 9999999999999, status:"active",$client: {offhooks: "true"} } })
    .then(({ data }:{ data: Product[]}) =>{
      setArrOptionsProducts( data.map((item: any )=>( {  value: item.id.toString(), title: item.name}) ))
    })
  }

  const { changeSelectValue,onSubmitCreateProductForBanner,  selectedValue, isLoading } = useBannersProducts()
  return (
    <WrapperModalBanners
      title={`Agregar un nuevo producto al banner`}
      open={modalIsOpen}
      width={"320px"}
      destroyOnClose
      onClose={()=>setModalIsOpen(false)}
    >
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Producto:
          </Title>
          <SimpleInputSelect
              showSearch
              valueSelect={selectedValue}
              onChange={changeSelectValue}
              label={"Seleccione producto"}
              dataOptions={
                arrOptionsProducts || []
              }
              maxWidth="100%"
            />
        </Col>

      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={()=>{
            onSubmitCreateProductForBanner()
            .then((res)=>{
                setModalIsOpen(false)
            })
        }}
        loading={isLoading}
      >
        Agregar producto al banner
      </Button>
    </WrapperModalBanners>
  )
}
