import { FormProvider } from "react-hook-form";
import { DiscountById, useCMS } from "../modules/discuontConfig";

export const DiscountByIdPage = () => {
  const { formMethodsUpdate } = useCMS();
  return (
  <FormProvider {...formMethodsUpdate}>
    <DiscountById />
  </FormProvider>
  )
};
