
import { Modal, Input } from 'antd';
import { ChangeEvent } from 'react';


const { TextArea } = Input;

export const ModalCancelOrder = ({
    refId,
  visible,
  onCancel,
  onOk,
  textAreaVal,
  changeTextArea,
  isErrorTextArea,
}:{refId: string,isErrorTextArea: boolean,textAreaVal: undefined | string,visible: boolean, onCancel: ()=>void, onOk:()=>void, changeTextArea:(val:ChangeEvent<HTMLTextAreaElement>)=>void}) => {

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={`Estás seguro de cancelar la orden con el id: ${refId}`}
      destroyOnClose
      cancelText={'Salir'}
      okText={'Cancelar orden'}
    >
       <>
        <p>
          {`Una vez ejecutada esta acción no hay vuelta atrás, estás por cancelar a la "Orden #${refId}", si deseas continuar dale click a "Cancelar orden" en caso tal no quieras solo oprime en "Salir".`}
        </p>
      </>
      <TextArea
        onChange={changeTextArea}
        value={textAreaVal}
        status={isErrorTextArea ? 'error' : ''}
        autoSize={{ minRows: 2, maxRows: 6 }}
        placeholder="Motivo de cancelación"
      />
      {isErrorTextArea && (
        <p style={{ color: 'red' }}>Debe llenar este campo para continuar.</p>
      )}
    </Modal>
  );
};
