import { Button, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { TableColumns } from "../../../shared/components/ui/Table/types";
import { DataCities } from "../../../redux/@types/cities";
import Table from "../../../shared/components/ui/Table";
import { citiesService } from "../../../shared/services";
import { useTableCities } from "../hooks/useTableCities";
import { useCities } from "../hooks/useCities";

export const TableCities = () => {
  const { changeCityForUpdate, showDeleteConfirm } = useTableCities();
  const { setTableProps } = useCities();

  const columns: TableColumns<DataCities> = [
    {
      title: "ID",
      width: 10,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 17,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Estado",
      width: 14,
      dataIndex: ["state", "name"],
      key: "stateName",
    },
    {
      title: "ID integración",
      width: 100,
      dataIndex: "integration_id",
      key: "integration_id",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 7,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCityForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<DataCities>
      columns={columns}
      scroll={{ x: "max-content" }}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={citiesService}
      onLoad={setTableProps}
    />
  );
};
