import { Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { listCustomerGroupsService } from "../../../shared/services";
import { DataListCustomerGroups } from "../../../redux/@types/listCustomerGroups";
import { useListCustomerGroups, useTableListCustomerGroups } from "../hooks";

const { Title } = Typography;

export const TableListCustomerGroups = () => {
  const { showDeleteConfirm, changeListCustomerGroupsForUpdate } =
    useTableListCustomerGroups();
  const { setTableProps } = useListCustomerGroups();
  const columns: TableColumns<DataListCustomerGroups> = [
    {
      title: "ID",
      width: 2,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "name",
      width: 12,
      //   render: (_, item) => {
      //     return (
      //       <Col
      //         style={{
      //           display: "flex",
      //           width: "100%",
      //           flexDirection: "row",
      //         }}
      //       >
      //         <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
      //           {item.user[0]?.name}
      //         </Title>
      //       </Col>
      //     );
      //   },
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeListCustomerGroupsForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataListCustomerGroups>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      scroll={{ x: "max-content" }}
      service={listCustomerGroupsService}
      onLoad={setTableProps}
    />
  );
};
