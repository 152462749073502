import { Button, Col, Row, Typography, message } from "antd";
import { useSelector } from "react-redux";

import {
  selectIsUpdateProduct,
  selectProductForUpdate,
} from "../../../../redux";
import { IProducts } from "../../../../redux/@types";
import {
  InputData,
  InputHTML,
  InputNumber,
  InputSelect,
  InputSelectTree,
  InputText,
  PropsArrCategoriesFuncRes,
} from "../../../../shared/components";
import {
  PropsArrCategoriesRes,
  useProductModal,
  useProducts,
} from "../../hooks";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import Upload from "../../../../shared/components/ui/inputs/Upload";
import { FilePdfOutlined, DeleteOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const ProductsForm = () => {
  const isUpdateProducts = useSelector(selectIsUpdateProduct);

  const [isValidate, setIsValidate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsValidate(!isValidate);
    }, 1500);
  }, []);

  const { isLoading, onSubmitCreateOrUpdate } = useProducts();

  const { arrBrands, arrCategories, arrTax, brands, discountUnis } =
    useProductModal();

  const generateNestedCategories = (
    categories: PropsArrCategoriesRes[]
  ): any => {
    return categories.map((item) => ({
      title: item.name,
      value: item.id.toString(),
      ...(item.children && {
        children: generateNestedCategories(item.children),
      }),
    }));
  };

  const {
    control,
    handleSubmit: onSubmit,
    watch,
    setValue,
  } = useFormContext<IProducts>();
  const course = watch("course");
  const category_id_2 = watch("category_id_2");
  const category_id_3 = watch("category_id_3");
  const brand_id = watch("brand_id");
  const tax_rule_id = watch("tax_rule_id");
  const guide_path = watch("user_guide_path");
  //console.log({course, category_id_2, category_id_3,brand_id,tax_rule_id})

  return (
    <>
      {isValidate && (
        <Row justify="start">
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
            </Title>
            <InputText
              placeHolder="Producto ideal"
              name="name"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Código:
            </Title>
            <InputText placeHolder="123ABC" name="code" control={control} />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.6px" }} level={2}>
              Categorías
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Categoría principal:
            </Title>
            <InputSelectTree
              name="category_id"
              control={control}
              dataOptions={generateNestedCategories(arrCategories)}
              label="Categoría principal"
            />
            {/* <InputSelect
              name="category_id"
              control={control}
              dataOptions={arrCategories.map((item) => ({
                title: item.name,
                value: item.id.toString(),
              }))}
              label="Categoría principal"
            /> */}
          </Col>
          {/* <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Categoría secundaria:
            </Title>
            <InputSelect
              name="category_id_2"
              defaultValue={"1"}
              control={control}
              dataOptions={arrCategories.map((item) => ({
                title: item.name,
                value: item.id.toString(),
              }))}
              label="Categoría secundaria"
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Categoría terciaria:
            </Title>
            <InputSelect
              name="category_id_3"
              defaultValue={"1"}
              control={control}
              dataOptions={arrCategories.map((item) => ({
                title: item.name,
                value: item.id.toString(),
              }))}
              label="Categoría terciaria"
            />
          </Col> */}
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Marca:
            </Title>
            <InputSelect
              name="brand_id"
              // defaultValue={"1"}
              control={control}
              dataOptions={brands.map((item: any) => ({
                title: item.name,
                value: item.id.toString(),
              }))}
              label="Marca insignia"
              showSearch
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={2}>
              Precios
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Precio sin IVA
            </Title>
            <InputNumber placeHolder="0" name="price" control={control} min />
          </Col>
          {isUpdateProducts && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Precio con IVA
              </Title>
              <InputNumber
                placeHolder="0"
                name="price_with_tax"
                control={control}
                min
                isDisable
              />
            </Col>
          )}
          <Col
            span={6}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Porcentaje de descuento
            </Title>
            <InputNumber
              placeHolder="0"
              name="discount_percentage"
              control={control}
              min
            />
          </Col>
          <Col
            span={6}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Caducidad del descuento
            </Title>
            <InputData
              placeHolder="yyyy-mm-dd"
              name="discount_end_date"
              control={control}
            />
          </Col>
          <Col
            span={6}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Precio en descuento sin IVA
            </Title>
            <InputNumber
              placeHolder="0"
              name="discount_price"
              control={control}
              min
              isDisable
            />
          </Col>
          {isUpdateProducts && (
            <Col
              span={6}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Precio en descuento con IVA
              </Title>
              <InputNumber
                placeHolder="0"
                name="discount_price_whit_tax"
                control={control}
                min
                isDisable
              />
            </Col>
          )}
          {arrTax && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                IVA %:
              </Title>
              <InputSelect
                name="tax_rule_id"
                control={control}
                defaultValue={arrTax[0]?.id!.toString()}
                dataOptions={arrTax.map((item) => ({
                  title: item.name,
                  value: item.id.toString(),
                }))}
                label="IVA %"
              />
            </Col>
          )}
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={4}>
              Descuento por Unidades
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            {/* <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descuento por unidades:
            </Title> */}
            <InputSelect
              name="discount_by_quantity_template_id"
              // defaultValue={"1"}
              control={control}
              dataOptions={discountUnis.map((item: any) => ({
                title: item.name,
                value: item.id.toString(),
              }))}
              label="Template de descuento por unidad"
              showSearch
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={4}>
              Cantidades
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Cantidad disponible:
            </Title>
            <InputNumber
              placeHolder="12345"
              name="quantity"
              control={control}
              min
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Unidad de medida:
            </Title>
            <InputText
              placeHolder="Unidad de medida"
              name="unit_measure"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Cantidad por unidad de medida:
            </Title>
            <InputNumber
              placeHolder="12345"
              name="quantity_per_unit_measure"
              control={control}
              min
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={4}>
              Producto redimencionado
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
            Producto redimencionado:
            </Title>
            <InputSelect
              name="oversize"
              control={control}
              defaultValue={"false"}
              dataOptions={[
                { title: "Si", value: "true" },
                { title: "No", value: "false" },
              ]}
              label="Selecciona si el producto es redimencionado"
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={2}>
              Peso y medidas
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Peso:
            </Title>
            <InputNumber
              placeHolder="Peso en kg"
              name="weight"
              control={control}
              min
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Alto:
            </Title>
            <InputNumber
              placeHolder="Alto en cm"
              name="heigh"
              control={control}
              min
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Ancho:
            </Title>
            <InputNumber
              placeHolder="Ancho en cm"
              name="width"
              control={control}
              min
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Largo:
            </Title>
            <InputNumber
              placeHolder="Largo en cm"
              name="long"
              control={control}
              min
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={2}>
              Descripción
            </Title>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descripción:
            </Title>
            {isUpdateProducts ? (
              <InputHTML
                placeHolder="Es un producto muy optimo para..."
                name="description"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un producto muy optimo para..."
                name="description"
                control={control}
              />
            )}
          </Col>
          {/* <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Composición y cuidado:
            </Title>
            {isUpdateProducts ? (
              <InputHTML
                placeHolder="Lorem Ipsum is simply dummy text of the printing..."
                name="user_guide_composition"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Lorem Ipsum is simply dummy text of the printing..."
                name="user_guide_composition"
                control={control}
              />
            )}
          </Col> */}
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Guía de uso:
            </Title>
            {guide_path && (
              <div style={{ display: "flex", gap: 5 }}>
                <FilePdfOutlined style={{ fontSize: 80 }} />
                <Button
                  type="primary"
                  danger
                  style={{ marginLeft: "1rem" }}
                  onClick={() => setValue("user_guide_path", "")}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </div>
            )}
            <Upload
              filePath="user_guide_path"
              placeHolder="Seleccione el archivo"
              onUploadedFile={(files) => {
                const file = files[0];
                setValue("user_guide_path", file.fileKey);
                message.success("Se ha subido la guía");
              }}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Guía de uso:
            </Title>
            {isUpdateProducts ? (
              <InputHTML
                placeHolder="Lorem Ipsum is simply dummy text of the printing..."
                name="user_guide_care"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Lorem Ipsum is simply dummy text of the printing..."
                name="user_guide_care"
                control={control}
              />
            )}
          </Col>
          {isUpdateProducts && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Estado:
              </Title>
              <InputSelect
                name="status"
                control={control}
                dataOptions={[
                  { title: "Activo", value: "active" },
                  { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
              />
            </Col>
          )}
          {/* <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tiene curso:
            </Title>
            <InputSelect
              name="course"
              control={control}
              defaultValue={"false"}
              dataOptions={[
                { title: "Si", value: "true" },
                { title: "No", value: "false" },
              ]}
              label="Selecciona si el producto tiene cursos"
            />
          </Col> */}
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "2.1px" }} level={2}>
              Posición
            </Title>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición en lo más vendido:
            </Title>
            <InputNumber
              //   stringMode={true}
              placeHolder="8"
              name="position_more_sales"
              control={control}
              min
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Button
              style={{
                marginTop: 10,
              }}
              type="primary"
              shape="round"
              size="large"
              onClick={onSubmit(onSubmitCreateOrUpdate)}
              loading={isLoading}
            >
              {isUpdateProducts ? "Actualizar información" : "Crear producto"}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
