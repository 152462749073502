// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Col, message, Row, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { DeleteOutlined } from "@ant-design/icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  selectIsUpdatePriceList,
  selectModalPriceList,
} from "../../../../redux";
import { ISinglePriceList, Price } from "../../../../redux/@types/priceList";
import {
  InputData,
  InputNumber,
  InputSelect,
  InputText,
  ModalPricesList,
  SimpleInputSelectMultiple,
} from "../../../../shared/components";
import { decimal } from "../../../../shared/utils/Helper";
import { usePriceList } from "../../hooks";
import { useEffect, useState } from "react";
import { listCustomerGroupsService } from "../../../../shared/services";

const { Title } = Typography;

interface Props {
  dataUpdatePriceList: ISinglePriceList;
}

export const FormPriceList = ({ dataUpdatePriceList }: Props) => {
  const [customerGroups, setCustomerGroups] = useState<any[]>([]);
  const { isLoading, onSubmitCreateOrUpdate, changeModalPriceListState } =
    usePriceList();
  const isModalPriceList = useSelector(selectModalPriceList);
  const isUpdatePriceList = useSelector(selectIsUpdatePriceList);
  const {
    control,
    handleSubmit: onSubmit,
    setValue,
    watch,
  } = useFormContext<ISinglePriceList>();
  const customer_groups = watch("customer_groups");
  // console.log('response===>',customer_groups);
  const { append, remove, fields } = useFieldArray({
    control,
    shouldUnregister: true,
    name: "prices",
    keyName: "formId",
  });
  // console.log({fields});
  const {
    append: append2,
    remove: remove2,
    fields: fields2,
  } = useFieldArray({
    control,
    shouldUnregister: true,
    name: "customer_groups",
    keyName: "formId2",
  });
  // console.log({fields2});
  const getListOfCustomerGroups = async () => {
    await listCustomerGroupsService
      .find({ query: { $limit: 9999999999999 } })
      .then((res: any) => {
        // console.log({ res });
        setCustomerGroups(
          res.data.map((item) => ({
            value: item.id.toString(),
            title: `${item.name}`,
          }))
        );
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    getListOfCustomerGroups();
  }, []);

  const columns: ColumnsType<Price> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "ID del producto",
      dataIndex: ["product", "id"],
      key: "product_id",
    },
    {
      title: "Nombre del producto",
      dataIndex: ["product", "name"],
      key: "product_name",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>$ {decimal(item.price)}</Title>
        </Col>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 5,
      render: (_, item, idx) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              danger
              shape="circle"
              onClick={() => remove(idx)}
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  // console.log({ fields, customerGroups });
  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title
          style={{
            marginBottom: "1.5px",
            color: "var(--primary)",
            marginRight: 7,
          }}
          level={2}
        >
          {isUpdatePriceList
            ? `Actualizando lista de precios:${dataUpdatePriceList?.id!}`
            : `Creando nueva lista de precios`}
        </Title>
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText
          placeHolder="Curso ejemplo 1"
          name="name"
          control={control}
        />
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Descripción:
        </Title>
        <InputText
          placeHolder="Lista enfocada para..."
          name="description"
          control={control}
          rows
        />
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Prioridad:
        </Title>
        <InputNumber placeHolder="8" name="priority" control={control} min />
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Fecha de inicio:
        </Title>
        <InputData
          dateFormat="YYYY-MM-DD HH:mm:ss"
          placeHolder="29/10/2022 00:00:00"
          name="start_at"
          control={control}
        />
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Fecha de finalización:
        </Title>
        <InputData
          dateFormat="YYYY-MM-DD HH:mm:ss"
          placeHolder="29/10/2022 00:00:00"
          name="ends_at"
          control={control}
        />
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Estado:
        </Title>
        <InputSelect
          name="status"
          control={control}
          dataOptions={[
            { title: "Activo", value: "active" },
            { title: "Inactivo", value: "inactive" },
          ]}
          label="Estado"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Grupos de clientes:
        </Title>
        <SimpleInputSelectMultiple
          showSearch
          valuesSelect={fields2.map((item: any) => `${item.customer_group_id}`)}
          onChange={(val) =>
            // append2({ customer_group_id: val[val.length - 1] })
            setValue(
              "customer_groups",
              val.map((it) => ({ customer_group_id: it }))
            )
          }
          label={"Seleccione un Listado "}
          dataOptions={customerGroups || []}
          clearValue={(e) => {
            setValue(
              "customer_groups",
              fields2.filter((it) => it.customer_group_id != e)
            );
          }}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Precios:
        </Title>
        <Button
          style={{
            width: "180px",
            margin: "10px 0",
          }}
          type="primary"
          shape="round"
          size="large"
          onClick={changeModalPriceListState}
        >
          Crear precio
        </Button>
        <Table
          style={{ width: "100%" }}
          // pagination={false}
          bordered
          columns={columns}
          dataSource={fields}
        />
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Button
          style={{
            width: "100%",
            marginTop: 10,
          }}
          type="primary"
          shape="round"
          size="large"
          onClick={onSubmit(onSubmitCreateOrUpdate)}
          loading={isLoading}
        >
          {isUpdatePriceList
            ? `Actualizar información`
            : `Crear lista de precios`}
        </Button>
      </Col>
      {isModalPriceList && (
        <ModalPricesList
          dataFilter={fields?.map((item) => item.product_id)!}
          resFunction={(e) =>
            append({
              price: e.valueNumber,
              product_id: Number(e.valueSelector),
              product: {
                id: Number(e.valueSelector),
                name: e.valueSelectorName,
              },
            })
          }
        />
      )}
    </Row>
  );
};
