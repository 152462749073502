import { message, Modal } from "antd";
import { useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useStores } from "./useStores";
import { IStore } from "../../../redux/@types";
import {
  deleteStore,
  getCitiesArr,
  refreshTableStores,
  selectCitiesForStoresArr,
  setCitiesStoresArr,
  setIsStoresForUpdate,
  setStoresForUpdate,
  storesChangeModalState,
} from "../../../redux";

const { confirm } = Modal;

export const useTableStores = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useStores();
  const arrCities = useSelector(selectCitiesForStoresArr);

  const getServicesProduct = async () => {
    await getCitiesArr()
      .then((res) => dispatch(setCitiesStoresArr(res)))
      .catch((res) => {
        alert({ res });
        dispatch(setCitiesStoresArr([]));
      });
  };

  useEffect(() => {
    if (arrCities.length === 0) {
      getServicesProduct();
    }
  }, [arrCities]);

  const changeStoresForUpdate = (val: IStore) => {
    dispatch(setIsStoresForUpdate(true));
    dispatch(setStoresForUpdate(val));
    dispatch(storesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la tienda con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar tienda", en caso tal no quieras borrar nada solo oprime en "Cancelar".`,
      okText: "Borrar tienda",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteStore(valId)
          .then(() => {
            dispatch(refreshTableStores(true));
            message.success(
              `Se ha borrado con éxito la tienda con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    arrCities,
    //methods
    //functions
    changeStoresForUpdate,
    showDeleteConfirm,
  };
};
