import { fulfillmentCitiesAllService } from "../../../../shared/services"

const api = {
    markOrUnmarkAllCities: async (fulfillmentCompanyId: number) => {
        return await fulfillmentCitiesAllService.create({fulfillment_company_id: fulfillmentCompanyId})
        .then(({data})=>{return data})
        .catch(error => {
            console.log({error})
            return error
        })
    }
}

export default api