import { Row } from "antd";
import { useParams } from "react-router-dom";

import { changeActiveKeyOfFulfillmentCompany } from "../../../../redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { AdminMenuLayout } from "../../../../shared/components";
import {
  WrapperCardFulfillmentCompany,
  WrapperTabNavFulfillmentCompany,
} from "../styled";
import { Price } from "./Price";
import { TableFulfillmentCompanyById } from "./TableFulfillmentCompanyById";


export const FulfillmentCompanyById = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  return (
    <AdminMenuLayout
      pageDescription={`FulfillmentCompanyBy${id}`}
      title={`FulfillmentCompanyBy${id}`}
    >
      <WrapperCardFulfillmentCompany>
        <WrapperTabNavFulfillmentCompany
          onChange={(num) =>
            dispatch(changeActiveKeyOfFulfillmentCompany(Number(num)))
          }
          items={[
            {
              label: `ZONA DE COBERTURA`,
              key: "1",
              children: <TableFulfillmentCompanyById />,
            },
            {
              label: `PRECIOS`,
              key: "2",
              children: (
                <Row
                  gutter={[8, 8]}
                  style={{
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  <Price  />
                </Row>
              ),
            },
          ]}
        />
      </WrapperCardFulfillmentCompany>
    </AdminMenuLayout>
  );
};
