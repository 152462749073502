import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Typography, message } from "antd";
import * as yup from "yup";

import { useNavigate, useParams } from "react-router-dom";
import { useCMS } from "../../hook";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { discountsDetailsService } from "../../../../shared/services";
import { ModalDiscount } from "./ModalUsers";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import confirm from "antd/lib/modal/confirm";

const { Title } = Typography;

export const TableDetails = () => {
  const { setTableProps } = useCMS();

  const { id } = useParams();

  const [modalVisible, setModalVisible] = useState(false);
  const [discount, setDiscount] = useState<any>(null);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = (data: any) => {
    setModalVisible(true);
    setDiscount(data);
    setIsUpdateUser(true);
  };

  const handleCancel = () => {
    formsMethod.reset();
    setModalVisible(false);
    setDiscount(null);
    setIsUpdateUser(false);
  };

  const onSubmitCreateOrUpdate = async (payoad: any) => {
    const data = {
      discount_by_quantity_template_id: Number(id),
      ...payoad,
    };
    setIsLoading(true);
    if (isUpdateUser) {
      await discountsDetailsService
        .patch(discount.id, data)
        .then((res) => {
          message.success("Descuento actualizado correctamente");
          handleCancel();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          message.error(err);
        });
    } else {
      await discountsDetailsService
        .create(data)
        .then((res) => {
          message.success("Descuento creado correctamente");
          setIsLoading(false);
          handleCancel();
        })
        .catch((err) => {
          setIsLoading(false);
          message.error(err);
        });
    }
  };

  const handleDiscount = () => {
    setModalVisible(true);
    setDiscount(null);
    setIsUpdateUser(false);
  };

  const validationDiscount = yup.object().shape({
    discount_percentage: yup
      .number()
      .typeError("Debe ser un número")
      .required("Campo requerido"),
    min_quantity: yup
      .number()
      .typeError("Debe ser un número")
      .required("Campo requerido"),
    max_quantity: yup
      .number()
      .typeError("Debe ser un número")
      .required("Campo requerido"),
    descripcion: yup.string().required("Campo requerido"),
  });

  const formsMethod = useForm<any>({
    resolver: yupResolver(validationDiscount),
  });

  useEffect(() => {
    if (discount)
      formsMethod.reset({
        discount_percentage: discount.discount_percentage,
        min_quantity: discount.min_quantity,
        max_quantity: discount.max_quantity,
        position: discount.position,
        descripcion: discount.descripcion,
      });
    else formsMethod.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  const handleDelete = async (id: number) => {
    confirm({
      title: `Estás seguro de borrar el Atributo con el id: ${id}`,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${id}", si deseas continuar dale click a "Borrar atributo" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await discountsDetailsService
          .remove(id)
          .then((res: any) => {
            message.success(`Se ha borrado el atributo "${res.descripcion}"`);
            formsMethod.reset();
          })
          .catch((err: any) => {
            console.log(err.message);
            return err.message;
          });
      },
      onCancel() {},
    });
  };

  const columns: TableColumns<any> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Descripción",
      width: 9,
      dataIndex: "descripcion",
      key: "descripcion",
      filterType: "text",
    },
    {
      title: "Posición",
      width: 9,
      dataIndex: "position",
      key: "name",
      filterType: "text",
    },
    {
      title: "Porcentaje de descuento",
      width: 9,
      dataIndex: "discount_percentage",
      key: "discount_percentage",
      filterType: "text",
    },
    {
      title: "Cantidad Mínima",
      width: 9,
      dataIndex: "min_quantity",
      key: "min_quantity",
      filterType: "text",
    },
    {
      title: "Cantidad Maxima",
      width: 9,
      dataIndex: "max_quantity",
      key: "max_quantity",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="default"
              shape="circle"
              onClick={() => handleEdit(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => handleDelete(item.id)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ textAlign: "left", width: "100%", margin: 10 }}>
        <Button onClick={() => handleDiscount()} type="primary">
          Crear Nuevo
        </Button>
      </div>
      <Table<any>
        columns={columns}
        fetchQueryProps={{
          $sort: { id: -1 },
          discount_by_quantity_template_id: id,
        }}
        service={discountsDetailsService}
        onLoad={setTableProps}
      />
      <FormProvider {...formsMethod}>
        <ModalDiscount
          handleCancel={handleCancel}
          modalVisible={modalVisible}
          userInfo={discount}
          isUpdateUser={isUpdateUser}
          isLoading={isLoading}
          onSubmitCreateOrUpdate={onSubmitCreateOrUpdate}
        />
      </FormProvider>
    </>
  );
};
