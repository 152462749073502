import { useForm } from 'react-hook-form';
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { refreshTableCMS } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { paymethodConfigService } from '../../../shared/services';
import { useCMSById } from './cmsById';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useCMS = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {dataEdit} = useCMSById()
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();
    const [dataTa, setDataT] = useState({})

    const fetchData = async (id: number) => {
      await paymethodConfigService.get(id).then((res: any) =>{
        formMethodsUpdate.reset({ ...res });
      })
    }


    const formMethodsUpdate = useForm({
    });

    const updateCustom = (data: any) => {
      navigate(`/paymentconfig/${data.id}`)
    }

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await paymethodConfigService.patch(id,
        {
        status: value,
      }
      )
      .then((res: any) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
          );
          dispatch(refreshTableCMS(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: any) => {
      if (isLoading) return;
      setIsLoading(true);
        await paymethodConfigService.patch(data.id,{
          ...data
        })
          .then(async(res: any) => {
            setIsLoading(false);
            // dispatch(mappingUpdatedUser(res))
            if (res.id) {
              // dispatch(cMSChangeModalState());
              navigate(`/paymentconfig`);
              message.success(
                `Se ha actualizado con éxito el Metodo de pago con id:${res.id}`
              );
              dispatch(refreshTableCMS(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
    };

    useEffect(() => {
      if (id){
        fetchData(Number(id))
      }
        tableProps?.refresh();
    }, [id]);

  return {
    //state
    isLoading,
    tableProps,
    //methods
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    updateStatus,
    updateCustom,
  }
}
