import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Typography } from "antd";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { cMSService, paymethodConfigService } from "../../../shared/services";
import { useCMS, useTableCMS } from "../hook";
import { DataCMS } from "../../../redux/@types/cms";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export const TablePayment = () => {
  const { setTableProps, updateStatus, isLoading, updateCustom } = useCMS();

  const columns: TableColumns<DataCMS> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Metodo de pago",
      width: 9,
      dataIndex: "paymment_method",
      key: "paymment_method",
      filterType: "text",
    },
    {
      title: "Pasarela de pago",
      width: 9,
      dataIndex: "gateway",
      key: "gateway",
      filterType: "text",
    },
    {
      title: "Estado",
      width: 7,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                if (item.status === "active") {
                  updateStatus("inactive", item.id);
                } else if (item.status === "inactive") {
                  updateStatus("active", item.id);
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => updateCustom(item)}
              icon={<EditOutlined />}
              size="large"
            />
            {/* <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.title}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            /> */}
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataCMS>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={paymethodConfigService}
      onLoad={setTableProps}
    />
  );
};
