import { Spin } from "antd";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout } from "../../../shared/components";
import { useProductById, useProducts } from "../hooks";
import {
  ProductsForm,
  ProductsGallery,
  TableProductCharacteristics,
} from "./productById";
import { WrapperProductById, WrapperTabNav } from "./styled";

export const ProductsById = () => {
  const { isUpdateProduct, formMethodsCreate, formMethodsUpdate } =
    useProducts();
  const { isValidate } = useProductById();

  return (
    <AdminMenuLayout pageDescription="ProductsById" title="ProductsById">
      <WrapperProductById>
        {!isValidate ? (
          <Spin size="large" />
        ) : (
          <>
            {!isUpdateProduct && (
              <FormProvider {...formMethodsCreate}>
                <ProductsForm />
              </FormProvider>
            )}
            {isUpdateProduct && (
              <WrapperTabNav
                items={[
                  {
                    label: `DATOS DEL PRODUCTO`,
                    key: "1",
                    children: (
                      <>
                        <FormProvider {...formMethodsUpdate}>
                          <ProductsForm />
                        </FormProvider>
                      </>
                    ),
                  },
                  {
                    label: `GALERÍA`,
                    key: "2",
                    children: <ProductsGallery />,
                  },
                  // {
                  //   label: `CARACT. DEL PRODUCTO`,
                  //   key: "3",
                  //   children: <TableProductCharacteristics />,
                  // },
                ]}
              />
            )}
          </>
        )}
      </WrapperProductById>
    </AdminMenuLayout>
  );
};
