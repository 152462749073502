// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { cMSService, paymethodConfigService } from "../../../../shared/services";

export const useCMSById = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isValidate, setIsValidate] = useState(false);
  const [dataEdit, setData] = useState({})

  const getSingleCourse = async () => {
    return await paymethodConfigService
      .find({})
      .then((res) => {
        setData(res?.data)
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        navigate(`/paymentconfig`);
        message.error(err.message);
      });
  };
  useEffect(() => {
    getSingleCourse()
  }, [pathname]);
  return {
    //states
    isValidate,
    dataEdit,
    //methods
    //functions
  };
};
