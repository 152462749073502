import { FormProvider } from "react-hook-form";
import { PaymentById, useCMS } from "../modules/paymethodConfig";

export const PaymentByIdPage = () => {
  const { formMethodsUpdate } = useCMS();
  return (
    <>
        <FormProvider {...formMethodsUpdate}>
          <PaymentById />
        </FormProvider>
    </>
  );
};
