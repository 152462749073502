// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ChangeEvent, useEffect, useState } from "react";
import { message, List, InputNumber, Card, Button } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import _ from "lodash";

import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { productMediaService } from "../../../../shared/services";
import { useSelector } from "react-redux";
import { selectProductForUpdate } from "../../../../redux";

const { Meta } = Card;
export const useProductsGallery = () => {
  const record = useSelector(selectProductForUpdate);
  const [valueSelect, setValueSelect] = useState("select");
  const [valueTextArea, setValueTextArea] = useState("");
  const [valueNumber, setValueNumber] = useState(0);

  const onChange = (val: string) => {
    setValueSelect(val);
  };

  const onChangeTextArea = (
    val: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValueTextArea(val.target.value);
  };

  const onChangeNumber = (val: number) => {
    setValueNumber(val);
  };

  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current_media, setCurrentMedia] = useState<any>();
  const [show, setShow] = useState(false);
  const [type, setType] = useState("image");
  const [urlYouTube, setUrlYouTube] = useState("");

  const UpdateListing = (file: any) => {
    if (file.key)
      productMediaService
        .create({
          product_id: record?.id,
          path: file.key,
          type: file.type,
          priority: file.index,
        })
        .then((response) => {
          getMedia();
          /* setMedia([
                    ...media,
                    response
                ].filter(it => (it.position))); */
        })
        .catch((err) => message.error(err.message));
  };
  const handleSaveEmbed = () => {
    productMediaService
      .create({
        product_id: record?.id,
        type,
        url_youtube: urlYouTube,
        priority: 0,
      })
      .then(() => {
        setType("image");
        setUrlYouTube("");
        getMedia();
      })
      .catch((err) => message.error(err.message));
  };

  const getYouTubePreview = (url: string) => {
    const query = new URLSearchParams(new URL(url).searchParams);
    const vID = query.get("v");
    return `https://img.youtube.com/vi/${vID}/hqdefault.jpg`;
  };

  const getYouTubeEmbed = (url: string) => {
    //console.log(url);
    const query = new URLSearchParams(new URL(url).searchParams);
    const vID = query.get("v");
    //console.log(1, vID);
    return `https://www.youtube.com/embed/${vID}`;
  };

  const handleDelete = (id: any) => {
    productMediaService
      .remove(id)
      .then((response) => {
        getMedia();
        message.info("Foto eliminada!");
      })
      .catch((err) => message.error(err.message));
  };
  const handleShow = (record: any) => {
    setShow(true);
    setCurrentMedia(record);
  };
  const handleOnChange = ({ key, type, ...file }: any) => {
    delete file.url;
    let files = media;
    file["uid"] = `${files.length}`;
    file["key"] = key;
    file["type"] = type;
    file["url"] = file.location;
    file["path"] = file.location;
    file["status"] = file.status === 204 ? "done" : "error";
    files.push(file);
    UpdateListing(file);
  };
  const handleposition = (value: any, record: any) => {
    setMedia([]);
    if (record.id)
      productMediaService
        .patch(record.id, {
          priority: value,
        })
        .then((response) => {
          /*  if (onChange) onChange(); */
          getMedia();
          message.success("Prioridad Actualizada");
        })
        .catch((err) => message.error(err.message));
  };

  const getMedia = () => {
    setLoading(true);
    productMediaService
      .find({
        query: {
          product_id: record?.id,
          $limit: 999999999,
          $sort: {
            priority: 1,
          },
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setMedia(data);
      })
      .catch((err) => message.error(err.message));
  };

  var handleOnposition = _.debounce(handleposition, 1000, { maxWait: 1000 });

  const renderItems = (record: any, index: any) => {
    let url = record.path || record.url;
    if (url && url.indexOf(PATH_S3_BUCKET) == -1)
      //modifique el url agregando "/"
      url = `${PATH_S3_BUCKET}/${url}`;
    return (
      <List.Item>
        <Card
          actions={[
            <Button
              className="delete"
              shape="circle"
              type="ghost"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id)}
            />,
            <Button
              className="show"
              shape="circle"
              type="ghost"
              icon={<EyeOutlined />}
              onClick={() => handleShow(record)}
            />,
          ]}
          cover={
            record.type === "url_youtube" ? (
              <img
                width="100%"
                height="100%"
                src={getYouTubePreview(record.url_youtube)}
                alt="media-preview"
              />
            ) : (
              <img src={url} alt={url} />
            )
          }
        >
          <Meta
            description={
              <>
                {index === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: "0px",
                    }}
                  >
                    <span style={{ color: "#fff", margin: 0 }}>
                      Imagen principal
                    </span>
                  </div>
                )}
                <span>Prioridad</span>
                <InputNumber
                  min={0}
                  defaultValue={record.priority}
                  name="priority"
                  onChange={(value) => handleOnposition(value, record)}
                />
              </>
            }
          />
        </Card>
      </List.Item>
    );
  };

  useEffect(() => {
    getMedia();
  }, []);

  return {
    //state
    valueSelect,
    valueTextArea,
    valueNumber,
    //methods
    //functions
    onChange,
    onChangeTextArea,
    onChangeNumber,

    //testing
    current_media,
    setShow,
    show,
    getYouTubeEmbed,
    renderItems,
    loading,
    media,
    handleSaveEmbed,
    setUrlYouTube,
    setType,
    type,
    handleOnChange,
    getMedia,
  };
};
