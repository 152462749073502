// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import { selectProductForUpdate, setIsProductsForUpdate, setProductsForUpdate } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { productsService } from "../../../shared/services";

export const useProductById = () => {
    const [isValidate, setIsValidate ] = useState(false)
    const { pathname } = useLocation()
    const dataUpdateProduct = useSelector(selectProductForUpdate);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getSingleProduct = async ( id: number) =>{
      return await productsService
      .get(id)
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        navigate(`/products`);
        message.error(err.message);
      });
    }

    useEffect(() => {
        if( pathname !== "/products/new" ){
          if (isNaN(pathname.split("/")[2])) return navigate(`/products`);
            dispatch(setIsProductsForUpdate(true));
            getSingleProduct(Number(pathname.split("/")[2])).then((res) =>
                dispatch(setProductsForUpdate(res as any))
              );
            setIsValidate(true)
        }else if(pathname === "/products/new" ){
            dispatch(setIsProductsForUpdate(false));
            setIsValidate(true)
        }
    }, [pathname])
  return {
    //state
    isValidate
    //methods
    //functions
  }
}
