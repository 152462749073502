import { useEffect } from "react";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  deleteProductCharacteristic2,
  refreshTableProductsCharacteristics,
  setIsProductsCharacteristicForUpdate,
  setProductsCharacteristicForUpdate,
  productsCharacteristicChangeModalState,
  selectProductCharacteristicsArr,
  getCharacteristicArr,
  setCharacteristicArr,
} from "../../../../redux";
import { useProductCharacteristics2 } from "./useProductCharacteristics2";
import { useSelector } from "react-redux";

const { confirm } = Modal;

export const useTableProductCharacteristics2 = () => {
  const dispatch = useAppDispatch();
  const arrCharacteristics = useSelector(selectProductCharacteristicsArr);
  const { tableProps } = useProductCharacteristics2();

  const getServicesProductCharacteristic = async () => {
    await getCharacteristicArr()
      .then((res) => dispatch(setCharacteristicArr(res)))
      .catch((res) => {
        alert({ res });
        dispatch(setCharacteristicArr([]));
      });
  };

  useEffect(() => {
    // if (arrCharacteristics.length === 0) {
    getServicesProductCharacteristic();
    //   }
    // }, [arrCharacteristics]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeProductCharacteristicForUpdate = (user: any) => {
    dispatch(setIsProductsCharacteristicForUpdate(true));
    dispatch(setProductsCharacteristicForUpdate(user));
    dispatch(productsCharacteristicChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el producto con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar producto" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar producto",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteProductCharacteristic2(valId)
          .then(() => {
            dispatch(refreshTableProductsCharacteristics(true));
            message.success(`Se ha borrado el producto con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    arrCharacteristics,
    //methods
    //functions
    changeProductCharacteristicForUpdate,
    showDeleteConfirm,
  };
};
