import { Col, Row, Typography } from "antd";
import { decimal } from "../../../../shared/utils/Helper";
import { GiftOutlined } from "@ant-design/icons";

import { WrapperCardUserOrder } from "../styled";

const { Title } = Typography;

interface Props {
  gift?: string;
  gift_from: string | null;
  gift_package_value: number;
  gift_to: string | null;
  gift_with_package: "false" | "true";
}

export const InfoGifCard = ({
  gift_from,
  gift_package_value,
  gift_to,
  gift_with_package,
}: Props) => {
  return (
    <WrapperCardUserOrder>
      <Row justify="start" style={{ width: "100%" }}>
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            margin: "auto",
            // padding: 2.5,
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <Col
            style={{
              width: "100%",
              display: "flex",
              margin: "auto",
              // padding: 2.5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "var(--primary)",
              }}
              level={5}
            >
              De:&nbsp;
            </Title>
            <Title
              style={{
                margin: 0,
                color: "var(--text-color-grey)",
              }}
              level={5}
            >
              {`${gift_from}`}
            </Title>
          </Col>
          <Col
            style={{
              width: "100%",
              display: "flex",
              margin: "auto",
              // padding: 2.5,
              justifyContent: "flex-end",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "var(--primary)",
              }}
              level={5}
            >
              <GiftOutlined />
            </Title>
          </Col>
        </Col>

        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            margin: "auto",
            // padding: 2.5,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <Title
            style={{
              marginBottom: "1.5px",
              color: "var(--primary)",
            }}
            level={5}
          >
            Para:&nbsp;
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            {`${gift_to}`}
          </Title>
        </Col>
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            margin: "auto",
            // padding: 2.5,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <Title
            style={{
              marginBottom: "1.5px",
              color: "var(--primary)",
            }}
            level={5}
          >
            Tiene empaque:&nbsp;
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            {`${gift_with_package === "false" ? "No" : "Si"}`}
          </Title>
        </Col>
        {gift_with_package === "true" && (
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              margin: "auto",
              // padding: 2.5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "var(--primary)",
              }}
              level={5}
            >
              valor del paquete:&nbsp;
            </Title>
            <Title
              style={{
                margin: 0,
                color: "var(--text-color-grey)",
              }}
              level={5}
            >
              $ {decimal(gift_package_value)}
            </Title>
          </Col>
        )}
      </Row>
    </WrapperCardUserOrder>
  );
};
