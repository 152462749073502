import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import {
  AdminMenuLayout,
  ModalBannersProducts,
} from "../../../shared/components";
import { WrapperCard } from "./styled";
import { useBannersProducts } from "../hooks";
import { TableBannersProducts } from "./TableBannersProducts";

export const BannersProducts = () => {
  const { modalIsOpen, setModalIsOpen } = useBannersProducts();
  return (
    <AdminMenuLayout pageDescription="BannersProducts" title="BannersProducts">
      <WrapperCard>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={() => setModalIsOpen(true)}
            >
              Agregar nuevo producto al banner
            </Button>
          </Col>
        </Row>
        <TableBannersProducts />
        {modalIsOpen && (
          <ModalBannersProducts
            setModalIsOpen={setModalIsOpen}
            modalIsOpen={modalIsOpen}
          />
        )}
      </WrapperCard>
    </AdminMenuLayout>
  );
};
