// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { InputNumber as InputNumberAnt } from "antd";

interface Props {
  placeHolder: string;
  stringMode?: boolean;
  min?: boolean;
  max?: number;
  isDisable?: boolean;
  value: number | null | undefined;
  onChange: (val: number) => void;
}

export const SimpleInputNumber = ({
  placeHolder,
  max,
  stringMode = false,
  min = false,
  isDisable = false,
  value,
  onChange,
}: Props) => {
  return (
    <InputNumberAnt
      disabled={isDisable}
      style={{ width: "100%" }}
      min={min ? -999 : 0}
      max={max ? max : 999}
      value={value && value}
      placeholder={placeHolder}
      onChange={onChange}
      stringMode={stringMode}
    />
  );
};
