// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import "moment/min/locales"
import { DatePicker, message } from "antd";
import moment from "moment-timezone";
import moment2 from "moment";
import { useEffect, useState } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";

import { InputError } from "./styled";

interface Props {
  width?: string;
  placeHolder: string;
  name: string;
  dateFormat?: string;
  control: any;
  defaultValue?: boolean;
}

interface PropsContent {
  errors: Partial<FieldErrorsImpl<{
    [x: string]: any;
  }>>;
  name: string;
  width: string;
  onChange: (...event: any[]) => void;
  value: any;
  placeHolder:string;
  dateFormat?: string;
  defaultValue?: boolean;
}

// const dateFormat = "DD-MM-YYYY";

const DataContent = ({width,dateFormat,errors, placeHolder, onChange, value, name, defaultValue }:PropsContent) => {
  const [localValue, setLocalValue] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if(errors?.[name]?.message){
      message.error(`${errors?.[name]?.message}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const funtAux = () => {
    if (value) {
      setLocalValue(moment2(value).utcOffset(0));
    }
  }

  useEffect(() => {
    funtAux()
  }, [value]);

  return(
    <div
            style={{
              display: "flex",
              justifyContent: "center",
              width,
              height: "80%",
              flexDirection: "column",
            }}
          >
            <DatePicker
              format={dateFormat ? dateFormat : "YYYY-MM-DD"}
              showTime={dateFormat ? true : false}
              style={{ width: "100%" }}
              disabledDate={(value) => value.isBefore(moment().add(-1, "day"))}
              status={errors[name] ? "error" : ""}
              placeholder={placeHolder}
              // onChange={onChange}
              onChange={(val:any)=>{
                onChange(val)
                // if(val === null){
                //   onChange(val)
                // }else if(value === undefined){
                //  onChange(val.utcOffset(-5)._d)
                // }
                // else{
                //   onChange( val.utcOffset(0)._d)
                //   }
                }
              }
              // defaultValue={ moment2(value).utcOffset(-5)}
              // defaultValue={localValue}
              // value={value && moment2(value)}
              // value={localValue}
              value={value  && value !== "Invalid date" && moment2(value)}
            />
            {!!errors[name] && (
              <InputError>{errors?.[name]?.message}</InputError>
            )}
          </div>
  )
}

export const InputData = ({
  width = "100%",
  placeHolder,
  dateFormat,
  name,
  control,
  defaultValue = false,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <DataContent
            placeHolder={placeHolder}
            errors={errors}
            name={name}
            onChange={onChange}
            value={value}
            width={width}
            dateFormat={dateFormat}
            defaultValue={defaultValue}
          />
        );
      }}
    />
  );
};