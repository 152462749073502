import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { refreshTableBannersProducts } from "../../../redux";
import { useBannersProducts } from "./useBannersProducts";
import { bannerProductsService } from "../../../shared/services";

const { confirm } = Modal;

export const useTableBannersProducts = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useBannersProducts();

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el producto del banner con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar producto del banner" en caso tal solo oprime en "
                  Cancelar".`,
      okText: "Borrar producto del banner",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await deleteProductForBanner(valId)
          .then(() => {
            dispatch(refreshTableBannersProducts(true));
            message.success(
              `Se ha borrado el producto del banner con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  const deleteProductForBanner = async (id: number) => {
    return await bannerProductsService
      .remove(id.toString())
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        return err.message;
      });
  };
  return {
    //State
    //methods
    //functions
    showDeleteConfirm,
  };
};
