import { Button, Checkbox, Col, Collapse, Row, Typography } from "antd";
import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import {
  SimpleInputNumber,
  SimpleInputSelect,
} from "../../../../shared/components";
import { usePrice } from "../../hooks";
import { CollapseFulfillmentCompany } from "../styled";
import { TableForPriceBySomeThing } from "./TableForPriceBySomeThing";

const { Panel } = Collapse;
const { Title } = Typography;

export const Price = () => {
  const {
    //state
    valueSelector,
    arrCitySelector,
    fulfillmentCities,
    singleFulfillmentCompany,
    valMax,
    valMin,
    price,
    isLoading,
    isAllArrCitySelector,
    //methods
    setFormByCities,
    //functions
    changeSelectValue,
    changeArrCitySelect,
    changeSelectOrClearALLCities,
    getValForPatchCities,
  } = usePrice();

  return (
    <>
      <Col
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        <Title
          style={{ marginBottom: "12.5px", color: "var(--primary)" }}
          level={3}
        >
          {singleFulfillmentCompany?.name}
        </Title>
      </Col>
      <Col
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <SimpleInputSelect
          valueSelect={valueSelector}
          onChange={changeSelectValue}
          label={"Seleccione un tipo"}
          dataOptions={[
            { title: "Peso", value: "weight" },
            { title: "Unidad", value: "units" },
            { title: "Precio", value: "price" },
            { title: "Volumen", value: "volume" },
          ]}
          maxWidth="200px"
        />
        <Button
          type="primary"
          danger={!isAllArrCitySelector ? false : true}
          shape="round"
          onClick={changeSelectOrClearALLCities}
          icon={
            !isAllArrCitySelector ? <CheckCircleOutlined /> : <CloseOutlined />
          }
          size="large"
        >
          {!isAllArrCitySelector ? "Marcar todas" : "Desmarcar todas"}
        </Button>
      </Col>
      <Col
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        {fulfillmentCities.map((item, idx) => {
          // console.log((arrCitySelector as number[]).includes(item.location_city_id as number))
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CollapseFulfillmentCompany
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusSquareOutlined
                      style={{
                        fontSize: "1rem",
                        alignSelf: "center",
                        color: "var(--primary)",
                      }}
                    />
                  ) : (
                    <PlusSquareOutlined
                      style={{
                        display: "flex",
                        fontSize: "1rem",
                        alignSelf: "center",
                        color: "var( --primary)",
                      }}
                    />
                  )
                }
                key={idx}
              >
                <Panel header={item.location_city.name} key={item.id}>
                  <TableForPriceBySomeThing
                    key={`${idx} A`}
                    type={valueSelector}
                    city_id={item.location_city_id}
                  />
                </Panel>
              </CollapseFulfillmentCompany>
              <Checkbox
                style={{
                  margin: "0 23px",
                }}
                checked={(arrCitySelector as number[]).includes(
                  item.location_city_id as number
                )}
                onChange={(e) => {
                  e.stopPropagation();
                  changeArrCitySelect(item.location_city_id);
                }}
              />
            </div>
          );
        })}
      </Col>
      {arrCitySelector.length !== 0 && (
        <>
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <Title
              style={{
                marginBottom: "12.5px",
                color: "var(--primary)",
                fontWeight: "blod",
              }}
              level={4}
            >
              Acciones en lote
            </Title>
          </Col>
          {!valueSelector && (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "12.5px" }} level={5}>
                Por favor seleccione un tipo para poder continuar
              </Title>
            </Col>
          )}
          {!!valueSelector && (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Row gutter={[8, 8]}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <SimpleInputNumber
                    placeHolder="Mínimo"
                    onChange={(e) =>
                      setFormByCities((afterValue) => ({
                        ...afterValue,
                        valMin: e,
                      }))
                    }
                    value={valMin}
                    max={999999999999}
                    isDisable={false}
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <SimpleInputNumber
                    placeHolder="Máximo"
                    onChange={(e) =>
                      setFormByCities((afterValue) => ({
                        ...afterValue,
                        valMax: e,
                      }))
                    }
                    value={valMax}
                    max={999999999999}
                    isDisable={false}
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <SimpleInputNumber
                    placeHolder="Precio"
                    onChange={(e) =>
                      setFormByCities((afterValue) => ({
                        ...afterValue,
                        price: e,
                      }))
                    }
                    value={price}
                    max={999999999999}
                    isDisable={false}
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    type="dashed"
                    disabled={isLoading}
                    onClick={getValForPatchCities}
                    shape="circle"
                    icon={<SaveOutlined />}
                    size="large"
                  />
                </Col>
              </Row>
            </Col>
          )}
        </>
      )}
    </>
  );
};
