import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";

import { WrapperCard } from "./styled";
import { TableUsers } from "./TableUser";
import { AdminMenuLayout, ModalUsers } from "../../../shared/components";
import { useUsers } from "../hooks/useUsers";
import { selectModalUser } from "../../../redux";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { usersService } from "../../../shared/services";

export const Users = () => {
  const modalUser = useSelector(selectModalUser);
  const {
    isUpdateUser,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalUserState,
  } = useUsers();
  // const [tableProps, setTableProps] = useState<TableProperties>();
  const handleDownload = async () => {
    await usersService
      .find({ query: { $client: { exportData: "true" } } })
      .then((res) => {
        window.open(res.data[0].objectUrl, "_blank");
      });
  };
  return (
    <AdminMenuLayout pageDescription="Users" title="Users">
      <WrapperCard>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              style={{ marginRight: "1rem" }}
              onClick={handleDownload}
            >
              Descargar listado de usuarios
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalUserState}
            >
              Agregar nuevo usuario
            </Button>
          </Col>
        </Row>
        <TableUsers />
      </WrapperCard>
      {modalUser && isUpdateUser && (
        <FormProvider {...formMethodsUpdate}>
          <ModalUsers />
        </FormProvider>
      )}
      {modalUser && !isUpdateUser && (
        <FormProvider {...formMethodsCreate}>
          <ModalUsers />
        </FormProvider>
      )}
    </AdminMenuLayout>
  );
};
