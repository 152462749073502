import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteCMS,
  refreshTableCMS,
  setCMSForUpdate,
  setIsCMSForUpdate,
} from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useCMS } from "./useCMS";
import { DataCMS } from "../../../redux/@types/cms";

const { confirm } = Modal;

export const useTableCMS = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useCMS();

  const changeCMSForUpdate = async (val: DataCMS) => {
    navigate(`/paymentconfig/${val.id}`);
  };

  // const showDeleteConfirm = (valId: number, val: string) => {
  //   confirm({
  //     title: `Estás seguro de borrar el CMS con el id: ${valId}`,
  //     icon: <ExclamationCircleOutlined />,
  //     content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar CMS" en caso tal solo oprime en "Cancelar".`,
  //     okText: "Borrar CMS",
  //     okType: "danger",
  //     cancelText: "Cancelar",
  //     keyboard: true,
  //     maskClosable: true,
  //     async onOk() {
  //       await deleteCMS(valId)
  //         .then(() => {
  //           dispatch(refreshTableCMS(true));
  //           message.success(`Se ha borrado con éxito el CMS con id:${valId}`);
  //           tableProps?.refresh();
  //         })
  //         .catch((err) => {
  //           console.log({ err });
  //           message.error(err.message);
  //         });
  //     },
  //     onCancel() {},
  //   });
  // };

  return {
    //state
    //methods
    //functions
    changeCMSForUpdate,
    // showDeleteConfirm,
  };
};
