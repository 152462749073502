// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useRef, useState } from "react";
import { Input, message, Tag, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Controller, FieldErrorsImpl } from "react-hook-form";

import { InputError } from "./styled";
import { IAttribute } from "../../../../redux/@types";
import { contactsDirectoryAttributes } from "../../../services";
import { selectContactsDirectoryForUpdate } from "../../../../redux";
import { useSelector } from "react-redux";

interface Props {
  placeHolder: string;
  name: string;
  control: any;
  width?: string;
}

interface PropsContent {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
}

const ErrorComponent = ({ errors, name }: PropsContent) => {
  useEffect(() => {
    if (errors?.[name]?.message) {
      message.error(`${errors?.[name]?.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  return (
    <>{!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}</>
  );
};

export const InputTags = ({
  placeHolder,
  name,
  control,
  width = "100%",
}: Props) => {
  const dataUpdateContactsDirectory = useSelector(
    selectContactsDirectoryForUpdate
  );

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value = [] }, formState: { errors } }) => {
        const createAttributes = async (val: string) => {
          return await contactsDirectoryAttributes
            .create({
              name: val,
              contact_directory_id: dataUpdateContactsDirectory?.id!,
            })
            .then((res: any) => {
              message.success(`Se ha guardado el tag "${val}"`);
              return res;
            })
            .catch((err: any) => {
              return err.message;
            });
        };

        const deleteAttributes = async (val: IAttribute) => {
          return await contactsDirectoryAttributes
            .remove(val.id)
            .then((res: any) => {
              return message.success(`Se ha borrado el tag "${res.name}"`);
            })
            .catch((err: any) => {
              return err.message;
            });
        };

        const handleClose = (removedTag: IAttribute) => {
          const newTags = value.filter(
            (tag: IAttribute) => tag.name !== removedTag.name
          );
          deleteAttributes(removedTag);
          // console.log(newTags);
          onChange(newTags);
        };

        const showInput = () => {
          setInputVisible(true);
        };

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
        };

        const handleInputConfirm = () => {
          if (inputValue && value.indexOf(inputValue) === -1) {
            createAttributes(inputValue).then((res) => {
              onChange([...value, res]);
            });
          }
          setInputVisible(false);
          setInputValue("");
        };

        const handleEditInputChange = (
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          setEditInputValue(e.target.value);
        };

        const handleEditInputConfirm = () => {
          const newTags = [...value];
          newTags[editInputIndex] = editInputValue;
          onChange(newTags);
          setEditInputIndex(-1);
          setInputValue("");
        };

        return (
          <>
            <>
              {value.map((tag: IAttribute, index: number) => {
                if (editInputIndex === index) {
                  return (
                    <Input
                      ref={editInputRef}
                      key={`${tag.id}CBA`}
                      size="large"
                      className="tag-input"
                      value={editInputValue}
                      onChange={handleEditInputChange}
                      onBlur={handleEditInputConfirm}
                      onPressEnter={handleEditInputConfirm}
                    />
                  );
                }

                const isLongTag = tag.name.length > 20;

                const tagElem = (
                  <Tag
                    color="var(--primary)"
                    // className="edit-tag"
                    style={{ margin: 2, width: "auto" }}
                    key={`${tag.id}+ABC`}
                    closable={true}
                    onClose={() => handleClose(tag)}
                  >
                    <span
                      onDoubleClick={(e) => {
                        if (index !== 0) {
                          setEditInputIndex(index);
                          setEditInputValue(tag.name);
                          e.preventDefault();
                        }
                      }}
                    >
                      {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
                    </span>
                  </Tag>
                );
                return isLongTag ? (
                  <Tooltip title={tag.name} key={tag.id}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                );
              })}
              {inputVisible && (
                <Input
                  ref={inputRef}
                  type="text"
                  size="large"
                  className="tag-input"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              )}
              {!inputVisible && (
                <Tag
                  style={{ margin: 2, cursor: "pointer" }}
                  className="site-tag-plus"
                  onClick={showInput}
                >
                  <PlusOutlined /> {placeHolder}
                </Tag>
              )}
            </>
            <ErrorComponent errors={errors} name={name} />
          </>
        );
      }}
    />
  );
};
