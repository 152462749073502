import { AdminMenuLayout } from "../../../shared/components";
import { WrapperCardCMS } from "./styled";
import { TablePayment } from "./TableCMS";

export const ConfigPayment = () => {

  return (
    <AdminMenuLayout pageDescription="CMS" title="CMS">
      <WrapperCardCMS>
        <TablePayment />
      </WrapperCardCMS>
    </AdminMenuLayout>
  );
};
