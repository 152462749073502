import { useForm } from 'react-hook-form';
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { refreshTableCMS } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { discountsTemplateService } from '../../../shared/services';
import { useCMSById } from './cmsById';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import confirm from 'antd/lib/modal/confirm';

export const useCMS = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {dataEdit} = useCMSById()
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();
    const [dataTa, setDataT] = useState({})

    const fetchData = async (id: number) => {
      await discountsTemplateService.get(id).then((res: any) =>{
        formMethodsUpdate.reset({ ...res });
      })
    }


    const formMethodsUpdate = useForm({
    });

    const updateCustom = (data: any) => {
      navigate(`/discount/${data.id}`)
    }

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await discountsTemplateService.patch(id,
        {
        status: value,
      }
      )
      .then((res: any) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
          );
          dispatch(refreshTableCMS(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: any) => {
      if (isLoading) return;
      setIsLoading(true);
      if (id === 'new') {
        await discountsTemplateService.create(data).then((res) => {
          setIsLoading(false);
          navigate(`/discount`);
          message.success(
            `Se ha creado con éxito`
          );
         return tableProps?.refresh();
        }).catch((err) => {
          return message.error(err.message);
        })
      } else {
        await discountsTemplateService.patch(data.id,{
          ...data
        })
          .then(async(res: any) => {
            setIsLoading(false);
            if (res.id) {
              navigate(`/discount`);
              message.success(
                `Se ha actualizado con éxito el Metodo de pago con id:${res.id}`
              );
              dispatch(refreshTableCMS(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      }
    };

    const handleDelete = async (id: number) => {
      confirm({
        title: `Estás seguro de borrar el Atributo con el id: ${id}`,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${id}", si deseas continuar dale click a "Borrar atributo" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await discountsTemplateService
            .remove(id)
            .then((res: any) => {
              message.success(`Se ha borrado el atributo "${res.descripcion}"`);
              tableProps?.refresh();
            })
            .catch((err: any) => {
              console.log(err.message);
              return err.message;
            });
        },
        onCancel() {},
      });
    };

    useEffect(() => {
      if (id){
        fetchData(Number(id))
      }
        tableProps?.refresh();
    }, [id]);

  return {
    //state
    isLoading,
    tableProps,
    //methods
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    updateStatus,
    updateCustom,
    handleDelete,
  }
}
