import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { useFulfillmentCompany, useTableFulfillmentCompany } from "../hooks";
import { DataFulfillmentCompany } from "../../../redux/@types";
import { fulfillmentCompanyService } from "../../../shared/services";

const { Title } = Typography;

export const TableFulfillmentCompany = () => {
  const navigate = useNavigate();
  const { changeFulfillmentCompanyForUpdate } = useTableFulfillmentCompany();
  const { setTableProps, updateStatus, isLoading } = useFulfillmentCompany();

  const columns: TableColumns<DataFulfillmentCompany> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      width: 9,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Descripción",
      width: 8,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Imagen",
      width: 7,
      dataIndex: "path",
      key: "path",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path}`} />
          </Col>
        );
      },
    },
    {
      title: "Tipo",
      width: 6,
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Estado",
      width: 9,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                if (item.status === "active") {
                  updateStatus("inactive", item.id);
                } else if (item.status === "inactive") {
                  updateStatus("active", item.id);
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Integración",
      width: 6,
      dataIndex: "integration",
      key: "integration",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 7,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeFulfillmentCompanyForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="dashed"
              // danger
              onClick={() => navigate(`/fulfillmentCompanyById/${item.id}`)}
              shape="circle"
              icon={<EyeOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataFulfillmentCompany>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={fulfillmentCompanyService}
      onLoad={setTableProps}
    />
  );
};
