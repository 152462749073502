import { Button, Col, Row, Spin, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectOrderModalIsOpen } from "../../../../redux/selectors/orders.selectors";
import { AdminMenuLayout, ModalGenerate } from "../../../../shared/components";
import { useOrderById, useValidateSingleOrder } from "../../hooks/orderById";
import { WrapperOrderById, WrapperOrderByIdSpin } from "../styled";
import { InfoGifCard } from "./InfoGifCard";
import { InfoOrderDetails } from "./InfoOrderDetails";
import { InfoUserCard } from "./InfoUserCard";
import { PaymentConfirmation } from "./PaymentConfirmation";
import { Shippings } from "./Shippings";
import { TableOrderById } from "./TableOrderById";
import { orderStatusService } from "../../../../shared/services";
import { IDataStatus } from "../TableOrders";
import { ModalCancelOrder } from "../../../../shared/components/ui/modals/ModalCancelOrder";
import { useParams } from "react-router-dom";
import { decimal } from "../../../../shared/utils/Helper";

const { Title } = Typography;

export const OrderById = () => {
  const params = useParams();
  const {
    orderForUpdate,
    isDisable,
    textAreaVal,
    isErrorTextArea,
    isModalVisible,
    setIsModalVisible,
    changeOrderModal,
    changeTextArea,
    changeProcessPaymentInStore,
    changeCancelProcessPayment,
  } = useOrderById();
  const { isValidate } = useValidateSingleOrder();
  const orderModalIsOpen = useSelector(selectOrderModalIsOpen);
  const [orderStatus, setOrderStatus] = useState<IDataStatus[]>([]);
  const getOrderStatus = async () => {
    await orderStatusService
      .find({ query: { $limit: 9999999999999 } })
      .then((res) => {
        setOrderStatus(res.data);
      });
  };

  useEffect(() => {
    getOrderStatus();
  }, []);
  return (
    <AdminMenuLayout pageDescription="OrderById" title="OrderById">
      {!isValidate && (
        <WrapperOrderByIdSpin>
          <Spin size="large" />
        </WrapperOrderByIdSpin>
      )}
      {isValidate && (
        <WrapperOrderById>
          <Row justify="start" style={{ width: "100%" }} gutter={[8, 8]}>
            {(orderForUpdate?.order_status_id === 1 ||
              orderForUpdate?.order_status_id === 3) && (
              <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  // padding: 5,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  danger
                  onClick={() => setIsModalVisible(true)}
                  shape="round"
                  disabled={isDisable}
                  icon={<DeleteOutlined />}
                  size="large"
                >
                  Cancelar proceso de pago
                </Button>
              </Col>
            )}
            <Col
              span={12}
              style={{
                width: "100%",
                display: "flex",
                // padding: 5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                  marginRight: 7,
                }}
                level={2}
              >
                Pedido # {orderForUpdate?.id}
              </Title>
              {orderForUpdate?.payment_method && (
                <Tag
                  style={{
                    fontSize: 24,
                    padding: 8,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  Método de pago:&nbsp;{" "}
                  {orderForUpdate?.payment_method === "online" ? (
                    `Online`
                  ) : orderForUpdate?.payment_method === "pay_in_store" ? (
                    <img src="/PickAndGo.svg" />
                  ) : null}
                </Tag>
              )}
            </Col>

            <Col
              span={12}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Title
                style={{
                  marginBottom: "0",
                  marginRight: "12.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Estado del pedido
              </Title>
              {orderStatus && (
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 27px",
                    cursor: "pointer",
                    borderRadius: "20px",
                    backgroundColor: orderStatus?.find(
                      (order) => order.id === orderForUpdate?.order_status_id
                    )?.color,
                  }}
                >
                  <Title
                    style={{
                      marginBottom: "1.5px",
                      color: "white",
                    }}
                    level={5}
                  >
                    {orderForUpdate?.status.name}
                  </Title>
                </Col>
              )}
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                // padding: 5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                  marginRight: 7,
                }}
                level={3}
              >
                {orderForUpdate?.integration_id && (
                  <>Integration Id # {orderForUpdate?.integration_id}</>
                )}
              </Title>
            </Col>
            <Col
              span={12}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Historial de los Estado del pedido
              </Title>
              {orderForUpdate?.history && (
                <TableOrderById
                  data={orderForUpdate?.history}
                  userName={`${orderForUpdate?.user?.first_name} ${orderForUpdate?.user?.last_name}`}
                />
              )}
            </Col>
            <Col
              span={12}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Datos del cliente
              </Title>
              {orderForUpdate?.shipping_address_meta_data && (
                <InfoUserCard
                  shipping={JSON.parse(
                    orderForUpdate?.shipping_address_meta_data
                  )}
                  dataUser={orderForUpdate?.user}
                  dataFactura={orderForUpdate?.billing_order_info}
                  person_type={orderForUpdate?.person_type}
                />
              )}
            </Col>
            {orderForUpdate?.gift === "true" && (
              <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  margin: "auto",
                  // padding: 2.5,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Title
                  style={{
                    marginBottom: "1.5px",
                    color: "var(--primary)",
                  }}
                  level={3}
                >
                  Datos del regalo
                </Title>
                {orderForUpdate?.gift && (
                  <InfoGifCard
                    gift={orderForUpdate.gift}
                    gift_from={orderForUpdate.gift_from}
                    gift_package_value={orderForUpdate.gift_package_value}
                    gift_to={orderForUpdate.gift_to}
                    gift_with_package={orderForUpdate.gift_with_package}
                  />
                )}
              </Col>
            )}
            {orderForUpdate?.order_status_id === 1 &&
              orderForUpdate?.payment_method === "pay_in_store" && (
                <Col
                  span={24}
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "auto",
                    // padding: 2.5,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Title
                    style={{
                      marginBottom: "3.5px",
                      color: "var(--primary)",
                    }}
                    level={3}
                  >
                    Cambiar estado del pedido
                  </Title>
                  <Button
                    type="primary"
                    shape="default"
                    disabled={isDisable}
                    size="large"
                    onClick={changeProcessPaymentInStore}
                  >
                    Marcar como pagado
                  </Button>
                </Col>
              )}
            {orderForUpdate?.reason_for_cancel && (
              <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  margin: "auto",
                  // padding: 2.5,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Title
                  style={{
                    marginBottom: "3.5px",
                    color: "var(--primary)",
                  }}
                  level={3}
                >
                  Razón de la cancelación del pedido
                </Title>
                <Title
                  style={{
                    marginBottom: "2-2px",
                    color: "var(--primary)",
                  }}
                  level={4}
                >
                  {orderForUpdate?.reason_for_cancel}
                </Title>
              </Col>
            )}
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  marginBottom: "3.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Detalles del Pedido
              </Title>
              {orderForUpdate?.order_details && (
                <InfoOrderDetails data={orderForUpdate?.order_details} />
              )}
              <div style={{textAlign: 'right', padding: 10, width: '100%'}}>
                {orderForUpdate?.total_price_shipping_cost_excl ? <div><b>Total Precio sin costo de envió:</b> {decimal(orderForUpdate?.total_price_shipping_cost_excl)}</div> : null}
                {orderForUpdate?.discount_total_amount ? <div><b>Total descuento:</b> {decimal(orderForUpdate?.discount_total_amount)}</div> : null}
                {orderForUpdate?.total_price ? <div><b>Total Precio:</b> {decimal(orderForUpdate?.total_price)}</div> : null}
                {orderForUpdate?.total_shipping_cost ? <div><b>Valor del envío:</b> {decimal(orderForUpdate?.total_shipping_cost)}</div> : null}
                {orderForUpdate?.discount_shipping_cost_amount ? <div><b>Valor de descuento de envío:</b> - {decimal(orderForUpdate?.discount_shipping_cost_amount)}</div> : null}
                {orderForUpdate?.total_payment_received ? <div><b>Total Pago recibido:</b> {decimal(orderForUpdate?.total_payment_received)}</div> : null}
              </div>
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ marginBottom: "3.5px", color: "var(--primary)" }}
                level={3}
              >
                Confirmación de Pagos
              </Title>
              {orderForUpdate?.order_details && <PaymentConfirmation />}
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ marginBottom: "3.5px", color: "var(--primary)" }}
                level={3}
              >
                Método de envío escogido por el cliente:{" "}
                {`${orderForUpdate?.fulfillment_company?.name || "Pick&Go!"}`}
              </Title>
              <Title
                style={{ marginBottom: "3.5px", color: "var(--primary)" }}
                level={3}
              >
                Envíos
              </Title>
              {orderForUpdate?.order_details && <Shippings />}
            </Col>
          </Row>
        </WrapperOrderById>
      )}
      {orderModalIsOpen && (
        <ModalGenerate
          // height={"50%"}
          width={"calc(89% - 20px)"}
          title={"Generar Envío"}
          onCancel={changeOrderModal}
        />
      )}
      {params?.id! && isModalVisible && (
        <ModalCancelOrder
          isErrorTextArea={isErrorTextArea}
          changeTextArea={changeTextArea}
          onCancel={() => setIsModalVisible(false)}
          visible={isModalVisible}
          onOk={changeCancelProcessPayment}
          textAreaVal={textAreaVal}
          refId={params?.id}
        />
      )}
    </AdminMenuLayout>
  );
};
