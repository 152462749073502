// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useParams } from "react-router-dom";

import { fulfillmentMatrixService } from "../../../../shared/services";

const { confirm } = Modal;

export interface DataFulfillmentMatrix {
  id: number;
  fulfillment_company_id: number;
  destination_city_id: number;
  destination_city_dane: string;
  type: string;
  min: number;
  max: number;
  price: number;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  fulfillment_company: FulfillmentCompany;
  destination_city: DestinationCity;
}

interface DestinationCity {
  id: number;
  name: string;
  dane_code: string;
  state_id: number;
  integration_id: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

interface FulfillmentCompany {
  id: number;
  name: string;
  description: string;
  path: string;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

interface Props {
  type?: string;
  city_id: number;
}

let initialStateForm = {
  max: undefined,
  min: undefined,
  price: undefined,
};

export const useTableForPriceBySomeThing = ({ city_id, type }: Props) => {
  const { id } = useParams();
  const [data, setData] = useState<DataFulfillmentMatrix[]>([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState<number>(-1);
  const [formState, setFormState] = useState(initialStateForm);
  const { max, min, price } = formState;

  const getFulfillmentMatrixByCity = async (city_id: number, type: string) => {
    await fulfillmentMatrixService
      .find({
        query: {
          fulfillment_company_id: Number(id),
          destination_city_id: city_id,
          type,
          $limit: 9999999999999,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (type) {
      getFulfillmentMatrixByCity(city_id, type);
    }
  }, [type]);

  const changeCreateState = () => {
    setIsCreate(true);
  };

  const changeUpdateState = (item: DataFulfillmentMatrix) => {
    setIsCreate(false);
    setIsUpdate(true);
    setIdForUpdate(item.id);
    setFormState({
      min: item.min,
      max: item.max,
      price: item.price,
    });
  };

  const exitUpdateState = () => {
    setIsUpdate(false);
    setIdForUpdate(-1);
    setFormState(initialStateForm);
  };

  const createFulfillmentMatrixByCity = async () => {
    console.log({ max, min, price });
    if (max < -1 || min < -1 || price < -1) {
      return message.error(
        `Es necesario llenar todos los campos para ejecutar la acción`
      );
    }
    if (isLoading) return;
    setIsLoading(true);
    return await fulfillmentMatrixService
      .create({
        fulfillment_company_id: Number(id),
        destination_city_id: city_id,
        max,
        min,
        price,
        type,
      })
      .then(() => {
        getFulfillmentMatrixByCity(city_id, type);
        setIsCreate(false);
        setFormState(initialStateForm);
        setIsLoading(false);
        return message.success(`se ha creado exitosamente la petición`);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        return message.error(err.message);
      });
  };

  const updateFulfillmentMatrixByCity = async (idForUpdate: number) => {
    if (max < -1 || min < -1 || price < -1) {
      return message.error(
        `Es necesario llenar todos los campos para ejecutar la acción`
      );
    }
    if (isLoading) return;
    setIsLoading(true);
    return await fulfillmentMatrixService
      .patch(idForUpdate, {
        fulfillment_company_id: Number(id),
        destination_city_id: city_id,
        max,
        min,
        price,
        type,
      })
      .then(() => {
        getFulfillmentMatrixByCity(city_id, type);
        exitUpdateState();
        setIsLoading(false);
        return message.success(
          `se ha actualizado exitosamente: ${idForUpdate}`
        );
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        return message.error(err.message);
      });
  };

  const showDeleteConfirmState = (valId: number) => {
    confirm({
      title: `Estás seguro de borrar el item con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, si deseas continuar dale click a "Borrar" en caso tal no quieras solo oprime en "Cancelar".`,
      okText: "Borrar",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await fulfillmentMatrixService
          .remove(valId)
          .then(() => {
            message.success(
              `Se ha borrado con éxito el item con el id:${valId}`
            );
            return getFulfillmentMatrixByCity(city_id, type);
          })
          .catch((err) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    data,
    isCreate,
    isLoading,
    max,
    min,
    price,
    isUpdate,
    idForUpdate,
    formState,
    //methods
    setFormState,
    //functions
    changeCreateState,
    changeUpdateState,
    showDeleteConfirmState,
    exitUpdateState,
    createFulfillmentMatrixByCity,
    updateFulfillmentMatrixByCity,
  };
};
