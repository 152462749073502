import { Navigate, Route, Routes } from "react-router-dom";

import {
  UsersPage,
  LoginPage,
  AddressesUsersPage,
  ProductCharacteristicsPages,
  ProductCategoriesPage,
  ProductCategoriesByIdPage,
  RatingsPage,
  BannersPage,
  ProductsPage,
  ProductsByIdPage,
  OrdersPage,
  OrdersByIdPage,
  TaxPage,
  BrandsPage,
  DiscountCodePage,
  ContactsDirectoryPage,
  DirectoryCategoriesPage,
  DirectoryCategoriesByIdPage,
  WalletBonusPages,
  WalletMovementsPage,
  CoursesCategoriesPage,
  CoursesPage,
  CoursesByIdPage,
  CMSPage,
  CMSByIdPage,
  WorkOffersPage,
  WorkOffersByIdPage,
  FulfillmentCompanyPage,
  FulfillmentCompanyByIdPage,
  ListCustomerGroupsPage,
  ListCustomerGroupsByIdPage,
  PriceListPage,
  PriceListByIdPage,
  BannersProductsPage,
  SystemVariablesPage,
  ContactsPage,
  StoresPages,
} from "../pages";
import { CitiesPage } from "../pages/CitiesPage";
import { StatesPage } from "../pages/StatesPage";
import { useAppRouter } from "./hooks";
import { WorkOffersCategoriesPage } from "../pages/WorkOffersCategoriesPage";
import { PaymenthPage } from "../pages/PaymenthPage";
import { PaymentByIdPage } from "../pages/PaymentByIdPage";
import { DiscountPage } from "../pages/DiscountPage";
import { DiscountByIdPage } from "../pages/DiscountByIdPage";

export const AppRouter = () => {
  const { auth } = useAppRouter();
  return (
    <Routes>
      <Route path="/" element={auth === true ? <Navigate to="/orders" replace/> : <Navigate to="/login" replace/>} />
      {auth && (
        <>
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/orders/:id" element={<OrdersByIdPage />} />
        <Route  path="/users" element={<UsersPage />} />
        <Route path="/addressesUsers" element={<AddressesUsersPage />} />
        <Route
          path="/productCharacteristics"
          element={<ProductCharacteristicsPages />}
        />
        <Route
          path="/productCategories"
          element={<ProductCategoriesPage />}
        />
        <Route
          path="/productCategories/:id"
          element={<ProductCategoriesByIdPage />}
        />
        <Route path="/ratings" element={<RatingsPage />} />
        <Route path="/banners" element={<BannersPage />} />
        <Route path="/bannersProducts/:id" element={<BannersProductsPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/:id" element={<ProductsByIdPage />} />
        <Route path="/tax" element={<TaxPage />} />
        <Route path="/cities" element={<CitiesPage />} />
        <Route path="/systemVariables" element={<SystemVariablesPage />} />
        <Route path="/states" element={<StatesPage />} />
        <Route path="/brands" element={<BrandsPage />} />
        <Route path="/discountCode" element={<DiscountCodePage />} />
        <Route
          path="/listCustomerGroups"
          element={<ListCustomerGroupsPage />}
        />
        <Route
          path="/listCustomerGroups/:id"
          element={<ListCustomerGroupsByIdPage />}
        />

        <Route
          path="/directoryCategories"
          element={<DirectoryCategoriesPage />}
        />
        <Route
          path="/directoryCategories/:id"
          element={<DirectoryCategoriesByIdPage />}
        />
        <Route
          path="/contactsDirectory"
          element={<ContactsDirectoryPage />}
        />
        <Route path="/walletBonus" element={<WalletBonusPages />} />
        <Route
          path="/walletMovements"
          element={<WalletMovementsPage />}
        />
        <Route
          path="/coursesCategories"
          element={<CoursesCategoriesPage />}
        />
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/courses/:id" element={<CoursesByIdPage />} />
        <Route path="/cms" element={<CMSPage />} />
        <Route path="/cms/:id" element={<CMSByIdPage />} />
        <Route
          path="/workOffersCategories"
          element={<WorkOffersCategoriesPage />}
        />
        <Route path="/workOffers" element={<WorkOffersPage />} />
        <Route path="/workOffers/:id" element={<WorkOffersByIdPage />} />
        <Route
          path="/fulfillmentCompany"
          element={<FulfillmentCompanyPage />}
        />
        <Route
          path="/fulfillmentCompanyById/:id"
          element={<FulfillmentCompanyByIdPage />}
        />
        <Route path="/priceList" element={<PriceListPage />} />
        <Route path="/priceList/:id" element={<PriceListByIdPage />} />
        <Route  path="/contacts" element={<ContactsPage />} />
        <Route  path="/stores" element={<StoresPages />} />

        {/* Payment */}
        <Route path="/paymentconfig" element={<PaymenthPage />} />
        <Route path="/paymentconfig/:id" element={<PaymentByIdPage />} />

        {/* Discount */}
        <Route path="/discount" element={<DiscountPage />} />
        <Route path="/discount/:id" element={<DiscountByIdPage />} />

        <Route path="/*" element={<Navigate to="/orders" replace/>} />
        </>
      )}
      { !auth &&(
        <>
          <Route  path="/login" element={<LoginPage />} />
          <Route path="/*" element={<Navigate to="/login" replace/>} />
        </>
      )}
    </Routes>
  );
};
