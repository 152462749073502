import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectActiveKeyOfFulfillmentCompany } from "../../../../redux";

import {
  fulfillmentCitiesService,
  fulfillmentCompanyService,
  fulfillmentMatrixService,
} from "../../../../shared/services";

interface DataFulfillmentCities {
  id: number;
  fulfillment_company_id: number;
  location_city_id: number;
  integration_city_id: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  fulfillment_company: FulfillmentCompany;
  location_city: LocationCity;
}

interface FulfillmentCompany {
  id: number;
  name: string;
  description: string;
  path: null;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

interface LocationCity {
  id: number;
  name: string;
  integration_id: string;
  state_id: number;
  dane_code: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

export interface DataCompany {
  id: number;
  name: string;
  description: string;
  path: null;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

let initialStateForm = {
  price: undefined,
  valMin: undefined,
  valMax: undefined,
};

export const usePrice = () => {
  const { id } = useParams();
  const [isRefresh, setIsRefresh] = useState(false)

  const activeKey = useSelector(selectActiveKeyOfFulfillmentCompany);
  const [isLoading, setIsLoading] = useState(false)
  const [valueSelector, setValueSelector] = useState<string | undefined>();
  const [arrCitySelector, setArrCitySelector] = useState< Array<number> | []>([])
  const [isAllArrCitySelector, setIsAllArrCitySelector] = useState(false)
  const [fulfillmentCities, setFulfillmentCities] = useState<
    DataFulfillmentCities[]
  >([]);
  const [formByCities, setFormByCities] = useState<{valMax:number | undefined, valMin:number | undefined , price:number | undefined }>(initialStateForm)
  const{valMax, valMin, price} =formByCities

  const changeIsRefresh = () =>{
    setIsRefresh(e=>!e)
  }

  const getValForPatchCities = async()=>{
    if (isLoading) return;
    if ( valMax as number < -1  ||  valMin as number < -1  ||  price as number < -1 ) {
      return message.error(
        `Es necesario llenar todos los campos para ejecutar la acción`
      );
    }
    let auxArr = [];
    for(let i=0; i < arrCitySelector.length; i++){
      auxArr.push({
        price,
        min:valMin,
        max:valMax,
        fulfillment_company_id: Number(id),
        destination_city_id: arrCitySelector[i],
        type: valueSelector
      })
    }
    setIsLoading(true);
    return await fulfillmentMatrixService
      .create(auxArr)
      .then(() => {
        setFormByCities(initialStateForm);
        return message.success(`se han creado exitosamente la petición`);
      })
      .catch((err: any) => {
        console.log(err);
        return message.error(err.message);
      })
      .finally(()=>{
        setIsLoading(false);
        changeIsRefresh()
      })
  }
  const [singleFulfillmentCompany, setSingleFulfillmentCompany] =
    useState<DataCompany>();
  const changeSelectValue = (val: string) => {
    setValueSelector(val);
  };

  const getSingleFulfillmentCompany = async () => {
    await fulfillmentCompanyService
      .get(Number(id))
      .then((res) => {
        setSingleFulfillmentCompany(res);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const getFulfillmentCities = async () => {
    fulfillmentCitiesService
      .find({
        query: { fulfillment_company_id: Number(id), $limit: 999999999 },
      })
      .then((res) => {
        setFulfillmentCities(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (activeKey === 2) {
      getFulfillmentCities();
      getSingleFulfillmentCompany();
    }
  }, [activeKey]);

  useEffect(() => {
    if(!!isRefresh){
      getFulfillmentCities();
    }
  }, [isRefresh])


  const changeArrCitySelect = (idVal: number) =>{
    if(!arrCitySelector.find((e)=> e === idVal)){
      setArrCitySelector([...arrCitySelector, idVal])
    } else {
      setArrCitySelector(arrCitySelector.filter((e)=> e !== idVal))
    }
  }

  const changeSelectOrClearALLCities = () =>{
    if(!isAllArrCitySelector){
      setArrCitySelector([...fulfillmentCities.map((item)=>{return item.location_city_id })])
      return setIsAllArrCitySelector(e => !e)
    }else if(isAllArrCitySelector){
      setArrCitySelector([])
      return setIsAllArrCitySelector(e => !e)
    }
  }

  return {
    //state
    valueSelector,
    arrCitySelector,
    fulfillmentCities,
    singleFulfillmentCompany,
    valMax,
    valMin,
    price,
    isLoading,
    isAllArrCitySelector,
    //methods
    setFormByCities,
    //functions
    changeSelectValue,
    changeArrCitySelect,
    changeSelectOrClearALLCities,
    getValForPatchCities,
  };
};
